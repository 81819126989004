import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Store_sign = (props: Props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.75 2.5V3.75H2.5V5H3.75V17.5H5V5H17.5V3.75H5V2.5H3.75ZM6.25 6.25V13.75H16.25V6.25H6.25ZM7.5 7.5H15V12.5H7.5V7.5Z" />
  </svg>
);

export default Icons8Store_sign;
