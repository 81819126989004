import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Phone = (props: Props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.76229 2.84369C6.35335 2.84369 5.95052 2.99017 5.61483 3.25873L5.566 3.28314L5.54158 3.30756L3.10018 5.8222L3.12459 5.84662C2.37081 6.54242 2.13887 7.58307 2.46541 8.48334C2.46846 8.48944 2.46236 8.50165 2.46541 8.50775C3.12764 10.4029 4.82137 14.062 8.12947 17.3701C11.4498 20.6904 15.1577 22.317 16.9918 23.0341H17.0162C17.9653 23.3515 18.9937 23.1257 19.7262 22.497L22.192 20.0312C22.8389 19.3842 22.8389 18.2612 22.192 17.6142L19.0181 14.4404L18.9937 14.3915C18.3468 13.7446 17.1993 13.7446 16.5523 14.3915L14.9898 15.954C14.4253 15.6824 13.0794 14.9866 11.7916 13.7568C10.5129 12.5361 9.85982 11.1323 9.61873 10.5829L11.1812 9.02045C11.8374 8.36432 11.8496 7.27179 11.1568 6.62787L11.1812 6.60345L11.108 6.53021L7.98299 3.30756L7.95858 3.28314L7.90975 3.25873C7.57406 2.99017 7.17122 2.84369 6.76229 2.84369ZM6.76229 4.40619C6.82027 4.40619 6.87825 4.43365 6.93319 4.47943L10.0582 7.67767L10.1314 7.75092C10.1253 7.74481 10.1772 7.82721 10.0826 7.92181L8.12947 9.87494L7.76326 10.2167L7.93416 10.705C7.93416 10.705 8.83138 13.1068 10.7174 14.9042L10.8883 15.0507C12.7041 16.7078 14.8433 17.6142 14.8433 17.6142L15.3316 17.8339L17.651 15.5146C17.7852 15.3803 17.7608 15.3803 17.8951 15.5146L21.0933 18.7128C21.2276 18.8471 21.2276 18.7983 21.0933 18.9326L18.7008 21.3251C18.3407 21.6334 17.9592 21.6974 17.5045 21.5449C15.7345 20.8491 12.2951 19.3384 9.22811 16.2714C6.13668 13.18 4.5223 9.67352 3.93026 7.97064C3.81124 7.65326 3.89669 7.18329 4.1744 6.94525L4.22322 6.89642L6.59139 4.47943C6.64632 4.43365 6.7043 4.40619 6.76229 4.40619Z" />
  </svg>
);

export default Icons8Phone;
