import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Aspect_ratio = (props: Props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.875 3.75V4.375V16.25H18.125V3.75H1.875ZM3.125 5H16.875V15H3.125V5ZM12.5 6.25V7.5H14.375V9.375H15.625V6.25H12.5ZM4.375 10.625V13.75H7.5V12.5H5.625V10.625H4.375Z" />
  </svg>
);

export default Icons8Aspect_ratio;
