import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Search = (props: Props) => (
  <svg
    viewBox="0 0 45 46"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M26.7189 4.57678C18.968 4.57678 12.6564 10.8884 12.6564 18.6393C12.6564 22.0066 13.8374 25.0938 15.8204 27.5162L4.61438 38.7223L6.63586 40.7438L17.8419 29.5377C20.2644 31.5208 23.3516 32.7018 26.7189 32.7018C34.4697 32.7018 40.7814 26.3901 40.7814 18.6393C40.7814 10.8884 34.4697 4.57678 26.7189 4.57678ZM26.7189 7.38928C32.9481 7.38928 37.9689 12.41 37.9689 18.6393C37.9689 24.8685 32.9481 29.8893 26.7189 29.8893C20.4896 29.8893 15.4689 24.8685 15.4689 18.6393C15.4689 12.41 20.4896 7.38928 26.7189 7.38928Z" />
  </svg>
);

export default Icons8Search;
