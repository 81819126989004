import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Map_pin = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.2501 4.79553C11.6356 4.79553 8.68762 7.74353 8.68762 11.358C8.68762 14.6539 11.1412 17.3749 14.3126 17.8326V26.358H16.1876V17.8326C19.359 17.3749 21.8126 14.6539 21.8126 11.358C21.8126 7.74353 18.8646 4.79553 15.2501 4.79553ZM15.2501 6.67053C17.8502 6.67053 19.9376 8.75794 19.9376 11.358C19.9376 13.9581 17.8502 16.0455 15.2501 16.0455C12.65 16.0455 10.5626 13.9581 10.5626 11.358C10.5626 8.75794 12.65 6.67053 15.2501 6.67053ZM15.2501 7.60803C13.1884 7.60803 11.5001 9.29626 11.5001 11.358H13.3751C13.3751 10.3107 14.2028 9.48303 15.2501 9.48303V7.60803Z" />
  </svg>
);

export default Icons8Map_pin;
