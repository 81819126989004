import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Timesheet = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2548_74)">
      <path d="M4.5 5.29553V25.9205H14.8125C14.6111 25.3199 14.4719 24.6937 14.4023 24.0455H6.375V10.9205H25.125V14.4069C25.7805 14.5497 26.4067 14.7731 27 15.0514V5.29553H4.5ZM6.375 7.17053H25.125V9.04553H6.375V7.17053ZM9.1875 13.733V15.608H11.0625V13.733H9.1875ZM12.9375 13.733V15.608H18.4746C19.3535 15.0441 20.324 14.6339 21.375 14.4069V13.733H12.9375ZM23.25 15.608C19.1191 15.608 15.75 18.9772 15.75 23.108C15.75 27.2389 19.1191 30.608 23.25 30.608C27.3809 30.608 30.75 27.2389 30.75 23.108C30.75 18.9772 27.3809 15.608 23.25 15.608ZM9.1875 17.483V19.358H11.0625V17.483H9.1875ZM12.9375 17.483V19.358H15.1934C15.5083 18.6842 15.9001 18.0543 16.3652 17.483H12.9375ZM23.25 17.483C26.3665 17.483 28.875 19.9916 28.875 23.108C28.875 26.2245 26.3665 28.733 23.25 28.733C20.1335 28.733 17.625 26.2245 17.625 23.108C17.625 19.9916 20.1335 17.483 23.25 17.483ZM22.3125 18.4205V24.0455H27V22.1705H24.1875V18.4205H22.3125ZM21.3457 19.358H21.375C21.3677 19.3617 21.353 19.3544 21.3457 19.358Z" />
    </g>
    <defs>
      <clipPath>
        <rect width={30} height={30} transform="translate(0.75 0.608032)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icons8Timesheet;
