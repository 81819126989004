import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Name_tag_woman = (props: Props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.7188 2.06244V3.62494H7.03125C5.74612 3.62494 4.6875 4.68356 4.6875 5.96869V20.8124C4.6875 22.0976 5.74612 23.1562 7.03125 23.1562H17.9688C19.2539 23.1562 20.3125 22.0976 20.3125 20.8124V5.96869C20.3125 4.68356 19.2539 3.62494 17.9688 3.62494H13.2812V2.06244H11.7188ZM7.03125 5.18744H17.9688C18.4102 5.18744 18.75 5.52726 18.75 5.96869V20.8124C18.75 21.2539 18.4102 21.5937 17.9688 21.5937H7.03125C6.58982 21.5937 6.25 21.2539 6.25 20.8124V5.96869C6.25 5.52726 6.58982 5.18744 7.03125 5.18744ZM12.5 7.53119C10.7833 7.53119 9.375 8.93952 9.375 10.6562C9.375 11.5562 9.77404 12.3562 10.3897 12.9282C9.33479 13.6296 8.59375 14.7706 8.59375 16.1249H10.1562C10.1562 14.8214 11.1965 13.7812 12.5 13.7812C13.8035 13.7812 14.8438 14.8214 14.8438 16.1249H16.4062C16.4062 14.8068 15.7021 13.693 14.6942 12.9847C14.9701 12.8065 15.2618 12.638 15.5075 12.4537C16.3388 11.8302 16.9586 11.2086 16.9586 11.2086L17.511 10.6562L16.9586 10.1038C16.9586 10.1038 16.3388 9.48214 15.5075 8.8587C14.6763 8.23527 13.6719 7.53119 12.5 7.53119ZM12.5 9.09369C12.8906 9.09369 13.8394 9.56149 14.5706 10.1099C14.9341 10.3826 14.9792 10.4503 15.2084 10.6562C14.9792 10.8621 14.9341 10.9298 14.5706 11.2025C13.8394 11.7509 12.8906 12.2187 12.5 12.2187C11.6276 12.2187 10.9375 11.5286 10.9375 10.6562C10.9375 9.78379 11.6276 9.09369 12.5 9.09369ZM8.59375 17.6874V19.2499H16.4062V17.6874H8.59375Z" />
  </svg>
);

export default Icons8Name_tag_woman;
