import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Business_building = (props: Props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.125 3.62494V20.8124H9.55505C9.56726 20.7636 9.58252 20.7209 9.59473 20.6751C9.60999 20.6232 9.61304 20.5775 9.6283 20.5286C9.6405 20.4767 9.65576 20.4218 9.66797 20.373C9.69238 20.2906 9.73206 20.2112 9.75647 20.1288C9.78699 20.0464 9.8175 19.9671 9.84802 19.8847C9.89075 19.7748 9.92126 19.6619 9.97009 19.5551C9.9884 19.5124 10.0067 19.4727 10.0311 19.43C10.0525 19.3751 10.0922 19.3171 10.1166 19.2652C10.1196 19.2591 10.1196 19.256 10.1227 19.2499H4.6875V5.18744H16.4062V9.09369C16.9495 9.09369 17.4744 9.17609 17.9688 9.32562V3.62494H3.125ZM6.25 6.74994V8.31244H9.375V6.74994H6.25ZM11.7188 6.74994V8.31244H14.8438V6.74994H11.7188ZM6.25 9.87494V11.4374H9.375V9.87494H6.25ZM11.7188 9.87494V11.4374H11.9263C12.3657 10.8057 12.9395 10.2747 13.6017 9.87494H11.7188ZM16.4062 10.6562C14.2578 10.6562 12.5 12.414 12.5 14.5624C12.5 15.7496 13.0493 16.8055 13.8916 17.5257C12.1399 18.4382 10.9375 20.2692 10.9375 22.3749H12.5C12.5 20.2082 14.2395 18.4687 16.4062 18.4687C18.573 18.4687 20.3125 20.2082 20.3125 22.3749H21.875C21.875 20.2692 20.6726 18.4382 18.9209 17.5257C19.7632 16.8055 20.3125 15.7496 20.3125 14.5624C20.3125 12.414 18.5547 10.6562 16.4062 10.6562ZM16.4062 12.2187C17.7094 12.2187 18.75 13.2593 18.75 14.5624C18.75 15.8655 17.7094 16.9062 16.4062 16.9062C15.1031 16.9062 14.0625 15.8655 14.0625 14.5624C14.0625 13.2593 15.1031 12.2187 16.4062 12.2187ZM6.25 12.9999V14.5624H9.375V12.9999H6.25ZM6.25 16.1249V17.6874H9.375V16.1249H6.25Z" />
  </svg>
);

export default Icons8Business_building;
