import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Tag_window = (props: Props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.5765 8.57246L13.4478 4.58163C12.8616 4.01496 12.0903 3.70288 11.2757 3.70288H4.36947C2.87613 3.70288 1.66113 4.91788 1.66113 6.41121V14.3279C1.66113 15.8212 2.87613 17.0362 4.36947 17.0362H11.2753C12.0899 17.0362 12.8615 16.7241 13.4474 16.1579L17.5761 12.167C18.0678 11.6916 18.3382 11.0537 18.3382 10.37C18.3382 9.68621 18.0678 9.04746 17.5765 8.57246ZM13.7499 11.2029C13.2895 11.2029 12.9165 10.83 12.9165 10.3695C12.9165 9.90913 13.2895 9.53621 13.7499 9.53621C14.2103 9.53621 14.5832 9.90913 14.5832 10.3695C14.5832 10.83 14.2103 11.2029 13.7499 11.2029Z" />
  </svg>
);

export default Icons8Tag_window;
