import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Calendar = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.43762 3.85803V4.79553H4.68762V25.4205H25.3126V4.79553H21.5626V3.85803H19.6876V4.79553H10.3126V3.85803H8.43762ZM6.56262 6.67053H8.43762V7.60803H10.3126V6.67053H19.6876V7.60803H21.5626V6.67053H23.4376V8.54553H6.56262V6.67053ZM6.56262 10.4205H23.4376V23.5455H6.56262V10.4205ZM12.1876 12.2955V14.1705H14.0626V12.2955H12.1876ZM15.9376 12.2955V14.1705H17.8126V12.2955H15.9376ZM19.6876 12.2955V14.1705H21.5626V12.2955H19.6876ZM8.43762 16.0455V17.9205H10.3126V16.0455H8.43762ZM12.1876 16.0455V17.9205H14.0626V16.0455H12.1876ZM15.9376 16.0455V17.9205H17.8126V16.0455H15.9376ZM19.6876 16.0455V17.9205H21.5626V16.0455H19.6876ZM8.43762 19.7955V21.6705H10.3126V19.7955H8.43762ZM12.1876 19.7955V21.6705H14.0626V19.7955H12.1876ZM15.9376 19.7955V21.6705H17.8126V19.7955H15.9376Z" />
  </svg>
);

export default Icons8Calendar;
