import * as React from "react";

import { cn } from "@/src/lib/utils";
import Icons8Map_pin from "../icons/icons8/icons8MapPin";
import Icons8Calendar from "../icons/icons8/icons8Calendar";
import Icons8Timesheet from "../icons/icons8/icons8Timesheet";
import Icons8Forward from "../icons/icons8/icons8Forward";
import Icons8Email_sign from "../icons/icons8/icons8EmailSign";
import Icons8Search from "../icons/icons8/icons8Search";
import Icons8Name_tag_woman from "../icons/icons8/icons8NameTagWoman";
import Icons8Business_building from "../icons/icons8/icons8BusinessBuilding";
import Icons8Password from "../icons/icons8/icons8Password";
import Icons8Phone from "../icons/icons8/icons8Phone";
import { ControllerFieldState } from "react-hook-form";
import Icons8Store_sign from "../icons/icons8/icons8StoreSign";
import Icons8Tag_window from "../icons/icons8/icons8TagWindow";
import Icons8Aspect_ratio from "../icons/icons8/icons8AspectRatio";
import Icons8Price_tag from "../icons/icons8/icons8PriceTag";
import Icons8Right from "../icons/icons8/icons8Right";
import Icons8Plus_math from "../icons/icons8/icons8PlusMath";

export enum ICONS {
  SEARCH = "search",
  LOCATION = "location",
  CALENDAR = "calendar",
  TIME = "time",
  ARROW_DOWN = "arrow_down",
  ARROW_RIGHT = "arrow_right",
  EMAIL = "email",
  NAME = "name",
  COMPANY = "company",
  PHONE = "phone",
  PASSWORD = "password",
  SIGN = "sign",
  TAG = "tag",
  ASPECT = "aspect",
  PRICE = "price",
  PLUS = "plus",
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: ICONS;
  suffixIcon?: ICONS;
  inputSize?: "sm" | "md" | "lg";
  iconsClassName?: string;
  fieldState?: ControllerFieldState;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      prefixIcon,
      iconsClassName,
      inputSize = "md",
      suffixIcon,
      fieldState,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("relative flex")}>
        {prefixIcon && (
          <div
            className={cn(
              "absolute left-0 center-vertical z-10",
              {
                "w-7.5 h-7.5 left-3.75": inputSize === "lg",
                "w-5 h-5 left-3.75": inputSize === "md",
                "w-4.5 h-4.5 left-2.5": inputSize === "sm",
              },
              iconsClassName
            )}
          >
            {icons[prefixIcon]}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-9 w-full rounded-md border border-gray-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
            { "py-6.25 px-3.75 text-lg min-h-12.5": inputSize === "lg" },
            { "py-5 px-3.75 min-h-12.5": inputSize === "md" },

            { "pl-15": prefixIcon && inputSize === "lg" },
            { "pr-15": suffixIcon && inputSize === "lg" },

            { "pl-10": prefixIcon && inputSize === "md" },
            { "pr-10": suffixIcon && inputSize === "md" },

            { "pl-8.75": prefixIcon && inputSize === "sm" },
            { "pr-8.75": suffixIcon && inputSize === "sm" },

            {
              "border-red-500 dark:border-red-500 focus-visible:ring-red-500 focus-visible:border-none ":
                fieldState?.error,
            },

            className
          )}
          ref={ref}
          {...props}
        ></input>
        {suffixIcon && (
          <div
            className={cn(
              "absolute right-0 center-vertical z-10",
              {
                "w-7.5 h-7.5 right-3.75": inputSize === "lg",
                "w-6.25 h-6.25 right-3.75": inputSize === "md",
                "w-4.5 h-4.5 right-2.5": inputSize === "sm",
              },
              iconsClassName
            )}
          >
            {icons[suffixIcon]}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export const icons: Record<ICONS, JSX.Element | null> = {
  [ICONS.LOCATION]: <Icons8Map_pin className="h-full w-full" />,
  [ICONS.SEARCH]: <Icons8Search className="h-full w-full" />,
  [ICONS.CALENDAR]: <Icons8Calendar className="h-full w-full" />,
  [ICONS.TIME]: <Icons8Timesheet className="h-full w-full" />,
  [ICONS.ARROW_DOWN]: <Icons8Forward className="h-full w-full" />,
  [ICONS.EMAIL]: <Icons8Email_sign className="h-full w-full" />,
  [ICONS.NAME]: <Icons8Name_tag_woman className="h-full w-full" />,
  [ICONS.COMPANY]: <Icons8Business_building className="h-full w-full" />,
  [ICONS.PASSWORD]: <Icons8Password className="h-full w-full" />,
  [ICONS.PHONE]: <Icons8Phone className="h-full w-full" />,
  [ICONS.SIGN]: <Icons8Store_sign className="h-full w-full" />,
  [ICONS.TAG]: <Icons8Tag_window className="h-full w-full" />,
  [ICONS.ASPECT]: <Icons8Aspect_ratio className="h-full w-full" />,
  [ICONS.PRICE]: <Icons8Price_tag className="h-full w-full" />,
  [ICONS.ARROW_RIGHT]: <Icons8Right className="h-full w-full" />,
  [ICONS.PLUS]: <Icons8Plus_math className="h-full w-full" />,
};

export { Input };
