import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Forward = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M26.7363 12.7662L25.3887 11.4186L15.75 21.0573L6.11133 11.4186L4.76367 12.7662L15.0762 23.0787L15.75 23.7233L16.4238 23.0787L26.7363 12.7662Z" />
  </svg>
);

export default Icons8Forward;
