import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Password = (props: Props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.5 2.84369C9.48792 2.84369 7.03125 5.30035 7.03125 8.31244V10.6562H4.6875V23.1562H20.3125V10.6562H17.9688V8.31244C17.9688 5.30035 15.5121 2.84369 12.5 2.84369ZM12.5 4.40619C14.6667 4.40619 16.4062 6.14569 16.4062 8.31244V10.6562H8.59375V8.31244C8.59375 6.14569 10.3333 4.40619 12.5 4.40619ZM6.25 12.2187H18.75V21.5937H6.25V12.2187ZM9.375 16.1249C8.9447 16.1249 8.59375 16.4759 8.59375 16.9062C8.59375 17.3365 8.9447 17.6874 9.375 17.6874C9.8053 17.6874 10.1562 17.3365 10.1562 16.9062C10.1562 16.4759 9.8053 16.1249 9.375 16.1249ZM12.5 16.1249C12.0697 16.1249 11.7188 16.4759 11.7188 16.9062C11.7188 17.3365 12.0697 17.6874 12.5 17.6874C12.9303 17.6874 13.2812 17.3365 13.2812 16.9062C13.2812 16.4759 12.9303 16.1249 12.5 16.1249ZM15.625 16.1249C15.1947 16.1249 14.8438 16.4759 14.8438 16.9062C14.8438 17.3365 15.1947 17.6874 15.625 17.6874C16.0553 17.6874 16.4062 17.3365 16.4062 16.9062C16.4062 16.4759 16.0553 16.1249 15.625 16.1249Z" />
  </svg>
);

export default Icons8Password;
