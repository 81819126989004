import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Right = (props: Props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.10547 2.67505L7.20703 3.57349L13.6328 9.99927L7.20703 16.425L8.10547 17.3235L14.9805 10.4485L15.4102 9.99927L14.9805 9.55005L8.10547 2.67505Z" />
  </svg>
);

export default Icons8Right;
