import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Plus_math = (props: Props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.375 3.125V9.375H3.125V10.625H9.375V16.875H10.625V10.625H16.875V9.375H10.625V3.125H9.375Z" />
  </svg>
);

export default Icons8Plus_math;
