import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Price_tag = (props: Props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 3.125L9.80469 3.30078L2.67578 10.5078L2.24609 10.9375L2.67578 11.3867L8.61328 17.3242L9.0625 17.7539L9.49219 17.3242L16.6992 10.1953L16.875 10V3.125H10ZM10.5273 4.375H15.625V9.47266L9.0625 15.9961L4.00391 10.9375L10.5273 4.375ZM13.75 5.625C13.4058 5.625 13.125 5.90576 13.125 6.25C13.125 6.59424 13.4058 6.875 13.75 6.875C14.0942 6.875 14.375 6.59424 14.375 6.25C14.375 5.90576 14.0942 5.625 13.75 5.625Z" />
  </svg>
);

export default Icons8Price_tag;
