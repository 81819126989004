import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const Icons8Email_sign = (props: Props) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 2.01416C7.76237 2.01416 1.875 7.90153 1.875 15.1392C1.875 22.3768 7.76237 28.2642 15 28.2642C22.2376 28.2642 28.125 22.3768 28.125 15.1392C28.125 7.90153 22.2376 2.01416 15 2.01416ZM15 3.88916C21.2243 3.88916 26.25 8.91485 26.25 15.1392C26.25 21.3635 21.2243 26.3892 15 26.3892C8.77569 26.3892 3.75 21.3635 3.75 15.1392C3.75 8.91485 8.77569 3.88916 15 3.88916ZM15 5.76416C9.83312 5.76416 5.625 9.97228 5.625 15.1392C5.625 20.306 9.83312 24.5142 15 24.5142C17.0995 24.5142 19.0472 23.8215 20.6104 22.652L19.4861 21.1505C18.2348 22.0866 16.688 22.6392 15 22.6392C10.8463 22.6392 7.5 19.2929 7.5 15.1392C7.5 10.9854 10.8463 7.63916 15 7.63916C19.1537 7.63916 22.5 10.9854 22.5 15.1392V16.2891C22.5 17.2661 21.7298 18.0377 20.7532 18.0377C19.7765 18.0377 19.0063 17.2657 19.0063 16.2891V10.5377H17.1313V11.3782C16.3242 10.7536 15.326 10.3656 14.2328 10.3656C11.6079 10.3656 9.46106 12.5143 9.46106 15.1392C9.46106 17.764 11.6079 19.9127 14.2328 19.9127C15.6336 19.9127 16.8859 19.2895 17.7612 18.3197C18.4152 19.2782 19.5143 19.9127 20.7532 19.9127C22.7428 19.9127 24.375 18.2783 24.375 16.2891V15.1392C24.375 9.97228 20.1669 5.76416 15 5.76416ZM14.2328 12.2406C15.8443 12.2406 17.1313 13.5276 17.1313 15.1392C17.1313 16.7507 15.8443 18.0377 14.2328 18.0377C12.6213 18.0377 11.3361 16.7507 11.3361 15.1392C11.3361 13.5276 12.6213 12.2406 14.2328 12.2406Z" />
  </svg>
);

export default Icons8Email_sign;
